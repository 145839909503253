import { getData } from './request.js'
/**
 * 星视频--查询订单的投后数据 接口
 */
export function investmentData(data) {
    return getData({
        url: 'StarVideos/investmentData/export',
        method: 'post',
        data: data
    })
}

/**
 * 星视频--视频订单分天数据获取
 */
export function investmentDataDaily(data) {
    return getData({
        url: 'StarVideos/investmentDataDaily/export',
        method: 'post',
        data: data
    })
}

/**
 * 星视频--四表合一导出
 */
export function Integration(data) {
    return getData({
        url: 'StarVideos/Integration/export2',
        method: 'post',
        data: data
    })
}

export function realtime(data) {
    return getData({
        url: 'StarVideos/realtime/export',
        method: 'post',
        data: data
    })
}
export function realtime2(data) {
    return getData({
        url: 'StarVideos/realtime2/export',
        method: 'post',
        data: data
    })
}
